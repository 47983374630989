<template>
    <div class="article-blog-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="article-blog__banner article-blog__banner--eclipse">
            <img
                class="article-blog__banner-img"
                src="@/assets/blog/buissonniere-e-commerce-success-story/buissonniere-e-commerce-success-story-img-1.jpg"
                alt="Navigating fashion & apparel online: Buissonniere's e-commerce success story"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="article-blog__banner-title">
                NAVIGATING FASHION & APPAREL ONLINE: BUISSONNIERE'S E-COMMERCE SUCCESS STORY
            </h1>
        </section>
        <div class="container article-blog section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        The Fashion & Apparel market is extremely competitive and saturated. With so many businesses vying for consumer attention, it can be difficult for a new brand to stand out and make a name for itself. Our client is a growing Belgian e-commerce company with several offline shops and worldwide shipping. Their existing online platform faced several challenges at some point, including underdeveloped features and numerous bugs, that’s why they decided to reach our experienced team.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Elevating User Experience and Efficiency
                    </h2>
                    <p>
                        Our team embarked on this mission by implementing a set of innovative custom features within Adobe Commerce. These solutions were designed not only to enhance the overall user experience but also to optimize backend operations.
                    </p>
                    <p>
                        Given our long experience of successful partnerships with e-commerce brands, we understand the significance of a secure and high-performing online platform. That’s why we decided to upgrade the store to the latest version of Adobe Commerce (2.4.6). We also updated core services such as PHP (to 8.1), MySQL, RabbitMQ, and Redis. It is worth noting, that during this process, we encountered and successfully resolved several crucial Adobe Commerce bugs and incompatibility issues with the new PHP version. This upgrade not only strengthened security but also considerably improved the speed and efficiency, leading to a more responsive user experience.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Real-time Monitoring for Better Performance
                    </h2>
                    <p>
                        One of the notable aspects of this project was our use of Adobe Commerce Cloud's New Relic infrastructure. Real-time monitoring helped our team to pinpoint performance issues and errors. This strategic move proved to be a game-changer, bringing substantial benefits to our client. For example, by proactively detecting and addressing performance issues, we achieved a significant reduction in execution time of database queries and slowdowns. The real-time monitoring allowed us to identify the root causes of any errors that arose, leading to swift and precise troubleshooting. This not only saved valuable time but also prevented potential revenue loss for our client resulting from unresolved technical glitches.
                    </p>
                    <p>
                        Our collaborative efforts with the client are a bright example of our continued commitment to delivering innovative, reliable, and high-performance web solutions for e-commerce businesses.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Navigating the Complex ERP Integration
                    </h2>
                    <p>
                        One of the most complex aspects of this project was seamless integration with Prios, a third-party ERP system, developed to support fashion brands from design to omnichannel distribution. This intricate task involved multiple data entities, including products, inventory, categories, customers, prices, images, orders, invoices, and returns. Another important thing was that Prios utilizes SOAP protocol, thereby demanding particular diligence.
                    </p>
                    <p>
                        We thoroughly researched the existing connector. Subsequently, we carried out a comprehensive refactoring process to eliminate identified issues and optimize performance. This included addressing bottlenecks, removing redundant code, and implementing data caching strategies to ensure data precision.
                    </p>
                    <p>
                        This integration was perhaps the most complex part of the project as Prios' SOAP-based API, while secure, lacked the flexibility needed to seamlessly integrate with our client's e-commerce platform. Our solution ensures that our client's product catalog stays up-to-date with daily updates and maintains real-time inventory accuracy. It wasn't just about fixing problems, rather, about optimizing the entire process.
                    </p>
                    <p>
                        The benefits for our client cannot be underrated. They now enjoy a flawlessly synchronized product catalog and inventory, resulting in improved customer satisfaction and reduced operational challenges they used to face. With data that's not just current but consistently accurate, our client has gained a competitive edge, allowing them to make informed decisions and provide their customers with a top-tier shopping experience every time they visit the website.
                    </p>
                </div>
                <div class="section_content__item">
                    <h2 class="section_content__item-title">
                        Seamless Integration with Adobe Commerce Services
                    </h2>
                    <p>
                        We integrated key Adobe Commerce SaaS Services into the platform. These integrations included:
                    </p>
                    <ul>
                        <li><b>Product Recommendations:</b> Using Adobe Sensei's AI and machine-learning algorithms, we incorporated dynamic product recommendations to boost conversions, increase revenue, and engage shoppers effectively.</li>
                        <li><b>Live Search:</b> We replaced the standard search capabilities with SaaS Live Search, optimizing server performance by relocating search operations to a dedicated service.</li>
                        <li><b>Catalog Service:</b> Enhancing the catalog management process for our client.</li>
                        <li><b>SaaS Price Indexing:</b> This optional module expedited price change processing for our client as one of those merchants dealing with large, complex catalogs and customer groups.</li>
                    </ul>
                    <p>In a nutshell, our partnership with Buissonniere showed our expertise in custom web development, ERP integration, and platform upgrades, and has not only revitalized their e-commerce website but also positioned them as a standout player in the competitive market, setting new standards in the industry.</p>
                    <p>We are proud to have played a pivotal role in this journey, showcasing our commitment to excellence as a trusted web development vendor. We invite e-commerce businesses seeking reliable and innovative web development solutions to collaborate with us and explore the opportunities that drive success. Contact our team to get a free consultation!</p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon"
                                ><img
                                    v-svg-inline
                                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                                    alt=""
                                /></span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";

export default {
    name: "BlogBuissonniere",
    data() {
        return {
            backHistory: history.state.back,
            tags:['Healthcare','Delivery','Calendar','Schedule','Delivery date'],
            scroll: false,
            position: null,
            text: `
              Would you like to receive more examples of what we are able to help you achieve?<br />
              Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
              Would you like us to propose ways of turning your ideas into reality?
            `,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    name: 'Blog',
                    link: '/blog',
                },
                {
                    name: 'Navigating fashion & apparel online: Buissonniere\'s e-commerce success story',
                },
            ],
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.article-blog-header {
    .article-blog-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 72px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .article-blog-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.article-blog-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background-color: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.article-blog {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.article-blog__banner {
    position: relative;
    height: 300px;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .article-blog__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.article-blog__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 72px;
        max-width: 1150px;
    }
}
.article-blog__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.article-blog__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
